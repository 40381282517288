// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { Row, Col } from 'antd/lib/grid';
import { MoreOutlined } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import { ObjectType, ShapeType, ColorBy } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import LabelSelector from 'components/label-selector/label-selector';
import ItemMenu from './object-item-menu';

interface Props {
    jobInstance: any;
    readonly: boolean;
    clientID: number;
    createdBy: string;
    scale: number;
    serverID: number | undefined;
    labelID: number;
    labels: any[];
    shapeType: ShapeType;
    objectType: ObjectType;
    color: string;
    colorBy: ColorBy;
    type: string;
    locked: boolean;
    changeColorShortcut: string;
    copyShortcut: string;
    pasteShortcut: string;
    propagateShortcut: string;
    toBackgroundShortcut: string;
    toForegroundShortcut: string;
    removeShortcut: string;
    changeColor(color: string): void;
    changeLabel(label: any): void;
    copy(): void;
    remove(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    annotateNext(frames: string, direction: string): void;
    toBackground(): void;
    toForeground(): void;
    resetCuboidPerspective(): void;
    changeScale(value: number): void;
}

function ItemTopComponent(props: Props): JSX.Element {
    const {
        readonly,
        clientID,
        createdBy,
        scale,
        serverID,
        labelID,
        labels,
        shapeType,
        objectType,
        color,
        colorBy,
        type,
        locked,
        changeColorShortcut,
        copyShortcut,
        pasteShortcut,
        propagateShortcut,
        toBackgroundShortcut,
        toForegroundShortcut,
        removeShortcut,
        changeColor,
        changeLabel,
        copy,
        remove,
        propagate,
        createURL,
        switchOrientation,
        annotateNext,
        toBackground,
        toForeground,
        resetCuboidPerspective,
        jobInstance,
        changeScale,
    } = props;

    const [menuVisible, setMenuVisible] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);

    const changeMenuVisible = (visible: boolean): void => {
        if (!visible && colorPickerVisible) return;
        setMenuVisible(visible);
    };

    const changeColorPickerVisible = (visible: boolean): void => {
        if (!visible) {
            setMenuVisible(false);
        }
        setColorPickerVisible(visible);
    };

    const labelName = labels.find((label) => label.id === labelID)?.name || 'Unknown Label';

    return (
        <Row
            align="middle"
            style={{
                display: 'flex',
                flexWrap: 'nowrap',
                gap: '10px', // Adds spacing between components
            }}
        >
            <Col>
                <Text style={{ fontSize: 12 }}>{clientID}</Text>
                <Text style={{ fontSize: 12, marginLeft: '5px' }}>{createdBy}</Text>
                <br />
                <Text
                    type="secondary"
                    style={{ fontSize: 10 }}
                    className="cvat-objects-sidebar-state-item-object-type-text"
                >
                    {type}
                </Text>
            </Col>
            <Col>
                <CVATTooltip title="Change current label">
                    <LabelSelector
                        disabled={readonly}
                        size="small"
                        labels={labels}
                        value={labelID}
                        onChange={changeLabel}
                        className="cvat-objects-sidebar-state-item-label-selector"
                    />
                </CVATTooltip>
            </Col>
            {labelName === 'Scale' && (
                <Col>
                    <CVATTooltip title="Enter value">
                        <input
                            type="text"
                            placeholder="Size"
                            value={scale}
                            onChange={(e) => changeScale(e.target.value)}
                            className="cvat-objects-sidebar-state-item-scale-input"
                            style={{ width: '100px' }}
                        />
                    </CVATTooltip>
                </Col>
            )}
            <Col>
                <Dropdown
                    visible={menuVisible}
                    onVisibleChange={changeMenuVisible}
                    placement="bottomLeft"
                    overlay={ItemMenu({
                        jobInstance,
                        readonly,
                        serverID,
                        locked,
                        shapeType,
                        objectType,
                        color,
                        colorBy,
                        colorPickerVisible,
                        changeColorShortcut,
                        copyShortcut,
                        pasteShortcut,
                        propagateShortcut,
                        toBackgroundShortcut,
                        toForegroundShortcut,
                        removeShortcut,
                        changeColor,
                        copy,
                        remove,
                        propagate,
                        createURL,
                        switchOrientation,
                        annotateNext,
                        toBackground,
                        toForeground,
                        resetCuboidPerspective,
                        changeColorPickerVisible,
                    })}
                >
                    <MoreOutlined />
                </Dropdown>
            </Col>
        </Row>
    );
}

export default React.memo(ItemTopComponent);
